<template>
    <div class="center">
        <div class="top">
            <div class="info">
                <div class="icon">
                    <CompAvtor :img_src="icon" size="100"/>
                </div>
                <div class="count">
                    <div class="nickname">{{nickname}}</div>
                    <div class="count_list">
                        <div @click.stop="goTab('/user/follow?tab=fans')">
                            <p>{{fans}}</p>
                            <p>粉丝</p>
                        </div>
                        <div @click.stop="goTab('/user/follow')">
                            <p>{{follow}}</p>
                            <p>关注</p>
                        </div>
                        <div>
                            <p>{{all_like_count}}</p>
                            <p>被赞和喜欢</p>
                        </div>
                        <!-- <div>
                            <p>122</p>
                            <p>积分</p>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="setting">
                <p>摩点ID: {{userId}}</p>
                <div class="btn_list">
                    <!-- <div class="btn" @click.stop="goTab('/user/info')"><span class="icon-edit2 icon"></span>编辑</div> -->
                    <div class="btn" @click.stop="goHomePage">个人空间<span class="icon-arrow icon"></span></div>
                </div>
            </div>
        </div>
        <!-- 摩点认证 -->
        <div v-if="auth_info" class="auth">
            <img v-if="(vip_type == 1)" src="https://p6.moimg.net/mdwxapp/auth_orange_icon.png" />
            <img v-else-if="(vip_type == 2)" src="https://p6.moimg.net/mdwxapp/auth_blue_icon.png" />
            <span>{{auth_info}}</span>
        </div>
        <p class="nick_cont">签名：{{user_content || '暂无'}}</p>
        <div class="cards">
            <div class="pro" v-if="userCpTag.user_cp_crowd_tag_flag || userCpTag.common_user_cp_crowd_flag || userCpTag.shop_user_cp_tag_flag || userCpTag.shop_supplier_tag_flag">
                <p class="card_title">业务管理</p>
                <div class="btn" v-if="userCpTag.user_cp_crowd_tag_flag || userCpTag.common_user_cp_crowd_flag" @click.stop="goProList">我发起的众筹</div>
                <div class="btn" v-if="userCpTag.shop_user_cp_tag_flag" @click.stop="goPop">进入我的店铺</div>
                <div class="btn" v-if="userCpTag.shop_supplier_tag_flag" @click.stop="goSupplierCenter">进入供货商后台</div>
                <div class="btn" v-if="userCpTag.user_cp_ntf_tag_flag" @click.stop="goLockerr">进入摩点宇宙</div>
            </div>
            <div v-if="cards.length > 0" class="other">
                <div v-for="(item, index) in cards" :key="index" :class="[{'dongtai':item.type == 7},{'lockker':item.type == 9},{'support':item.type == 1},{'yunyang':item.type == 'yunyang'}]">
                    <p class="card_title">{{item.title}}</p>
                    <div class="btn" @click="pushRouter(item)">查看更多</div>
                </div>
            </div>
        </div>
        <div :class="['nav',{'single':hasPro}]" v-if="nav.length > 0">
            <strong>我的订单</strong>
            <div class="order_list">
                <div class="item" v-for="(item, index) in nav" :key="index">
                    <div class="block" @click.stop="goOrderList(item.code)">
                        <!-- 全部 -->
                        <span v-if="item.code == 100" class="icon icon-orders"></span>
                        <!-- 待付款 -->
                        <span v-if="item.code == 101" class="icon icon-pay"></span>
                        <!-- 待发货 -->
                        <span v-if="item.code == 102" class="icon icon-send_goods"></span>
                        <!-- 待收货 -->
                        <span v-if="item.code == 103" class="icon icon-take_over_goods"></span>
                        <!-- 退款 -->
                        <span v-if="item.code == 105" class="icon icon-refund"></span>
                        <p>{{item.title}}</p>
                        <div class="num" v-if="item.code != 100 && item.num > 0">{{item.num}}</div>
                        <div class="num" v-if="all_count > 0 && item.code == 100">{{all_count}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pros" v-if="pro_list.length > 0">
            <strong>热门项目推荐</strong>
            <img v-if="scrollIndex > 1" src="https://p6.moimg.net/path/dst_project/1198698/202212/3011/2646/2212302646Rlaex12qJdYEj4Wgpnzpw98PL0ABGZ.png" alt="上一个" class="back" @click.stop="opScrollLeft('back')" />
            <img v-if="scrollIndex < pro_list.length - 2" src="https://p6.moimg.net/path/dst_project/1198698/202212/3011/2646/22123026467gZpxA0jlr4ydA1eaxzYO5dKVJ3kRD.png" alt="下一个" class="next" @click.stop="opScrollLeft('next')" />
            <div class="container" id="pro_list">
                <div class="pro_list">
                    <div class="pro-item" v-for="(item, index) in pro_list" :key="index">
                        <CompProCard :item="item" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import domain from '@/service/domain'
import { getLockerrCode, feedList, getNewOrderTitleList } from "@/api/index"
import CompAvtor from "@@/compose/avtor.vue"
import CompProCard from "@@/user/proCard.vue"
export default {
    components: {
        CompAvtor,
        CompProCard
    },
    data() {
        return {
            hasPro:false,
            // hasProNum:0,
            cards:[],
            icon:'',
            nickname:'',
            vip_type:null,
            auth_info:'',
            user_content:'',
            pro_list:[],
            scrollIndex:1,
            feedPage:1,
            nav:[
                {
                    title: "全部",
                    code: 100,
                    num: 0
                },
                {
                    title: "待付款",
                    code: 101,
                    num: 0
                },
                {
                    title: "待发货",
                    code: 102,
                    num: 0
                },
                {
                    title: "待收货",
                    code: 103,
                    num: 0
                },
                {
                    title: "退款/售后",
                    code: 105,
                    num: 0
                }
            ],
            all_count: 0
        }
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId,
            user: state => state.user.userDetail.user,
            follow: state => state.user.userDetail.follow_count || 0,
            fans: state => state.user.userDetail.follower_count || 0,
            all_like_count: state => state.user.userDetail.all_like_count || 0,
            post_count: state => state.user.userDetail.post_count,
            usercards: state => state.user.userDetail.cards,
            userCpTag: state => state.user.userCpTag,
        }),
    },
    watch: {
        user: {
            handler (val) {
                if(val){
                    this.icon = val.icon;
                    this.nickname = val.nickname;
                    this.vip_type = val.vip_type;
                    this.auth_info = val.auth_info;
                    this.user_content = val.user_content;
                }
            },
            immediate: true,
        },
        usercards: {
            handler (val) {
                if(val && val.length > 0){
                    this.cards = [];
                    this.usercards.forEach(ele => {
                        if(ele.type == 5){
                            this.hasPro = true;
                            // this.hasProNum = ele.num;
                        }
                        // if(ele.type == 9){
                        //     ele.title = '我的藏品 '+ele.num;
                        //     ele.methods = 'goDigital';
                        //     this.cards.push(ele);
                        // }
                        // if(ele.type == 1){
                        //     ele.title = '支持的项目 '+ele.num;
                        //     ele.path = '/user/support_project';
                        //     this.cards.push(ele);
                        // }
                    });
                    // if(this.post_count > 0){
                    //     this.cards.push({title:'我的动态 '+this.post_count,type:7,path:'https://zhongchou.modian.com/dongtai/index'});
                    // }
                    // this.cards.push({title:'云养计划',type:'yunyang',path:'/user/subscribe_project'});
                }
            },
            immediate: true,
        },
    },
    created() {
        // if (!this.userId){
        //     location.href = `${domain.account}`
        //     return;
        // }
        this.getFeedList();
        this.getOrderNav();
    },
    methods: {
        goHomePage() {
            this.$router.push(`/user/homepage/${this.userId}`)
        },
        pushRouter(item) {
            if(item.methods) return this[item.methods]()
            if (item.path) {
                if (item.path.indexOf('https://') > -1) {
                    if(item.type == 7){
                        let newTab = window.open('about:blank');
                        newTab.location.href = `${domain.zhongchou}/dongtai/index?tab=1`
                    }else{
                        location.href = item.path
                    }
                } else {
                    this.$router.push(item.path)
                }
            }
        },
        // 去数字藏品后台
        async goLockerr() {
            if (this.userId) {
                const { status, data } = await getLockerrCode()
                if (status == 0) {
                    window.open(`https://shop-dc.lockerr.cn/#/login?from=modian&code=${data.code}`)
                } else {
                    window.open(`https://shop-dc.lockerr.cn/#/login?from=modian`)
                }
            }
        },
        getOrderNav(){
            getNewOrderTitleList({ json_type: "json" }).then(res => {
                for (var key in res.data){
                    // this.all_count += res.data[key].count
                    this.nav.forEach(ele => {
                        if(ele.code == res.data[key].code){
                            ele.num = res.data[key].count
                        }
                    })
                }
            })
        },
        getFeedList(){
            let option = {
                page_size: 20,
                page: this.feedPage,
                ad_position:'recommend_feed_list'
            }
            feedList(option).then(res => {
                if(res.status == 0){
                    if(res.data.list && res.data.list.length > 0){
                        res.data.list.forEach(ele => {
                            if(ele.type == 'pro'){
                                if(this.pro_list.length < 10){
                                    ele.card_info.start_time = ele.card_info.start_time.split(' ')[0];
                                    ele.card_info.progress = ele.card_info.progress.replace( new RegExp( '%' , "g" ) , '' );
                                    this.pro_list.push(ele.card_info)
                                }
                            }
                        })
                        // if(this.pro_list.length < 10 && res.data.is_next && res.data.list){
                        //     this.feedPage++;
                        //     this.getFeedList();
                        // }
                    }
                }
            })
        },
        opScrollLeft(type){
            let currentScrollLeft = document.getElementById('pro_list').scrollLeft;
            if(type == 'back'){
                this.scrollIndex--;
                //document.getElementById('pro_list').scrollLeft = currentScrollLeft - 256;
                document.getElementById('pro_list').scrollTo({
                    left: currentScrollLeft - 256,
                    behavior: 'smooth',
                });
            }else{
                this.scrollIndex++;
                //document.getElementById('pro_list').scrollLeft = currentScrollLeft + 256;
                document.getElementById('pro_list').scrollTo({
                    left: currentScrollLeft + 256,
                    behavior: 'smooth',
                });
            }
        },
        goProList(){
            window.open(`${domain.member}/project_manage/index`)
        },
        goPop() {
            window.open(`${domain.center}/product`)
        },
        goSupplierCenter() {
            window.open(`${domain.member}/gonghuo/center`)
        },
        goTab(path){
            this.$router.push(path)
        },
        goOrderList(code){
            if(code == 105){
                window.location.href = `${domain.wap}/order/order_list?after=true`;
                return false;
            }
            this.$router.push({
                path: '/order/order_list',
                query: {
                    code:code
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.center {
    background: #fff;
    .btn{
        padding: 0 15px;
        display: flex;
        align-items: center;
        text-align: center;
        min-width: 94px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #B1B1B1;
        font-size: 16px;
        line-height: 38px;
        text-align: center;
        font-weight: normal;
        color: #7F7F7F;
        cursor: pointer;
        &:hover{
            border: 1px solid #1A1A1A;
            color: #1A1A1A;
        }
        .icon-edit2{
            padding-right: 5px;
        }
    }
    .top{
        display: flex;
        justify-content: space-between;
        padding: 0 0 30px 0;
        .info{
            display: flex;
            .icon{
                width: 100px;
                height: 100px;
                background: #E8E8E8;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .count{
                margin: 15px 0 0 20px;
                .nickname{
                    font-size: 24px;
                    font-weight: 500;
                    color: #1A1A1A;
                    height: 24px;
                    line-height: 24px;
                    font-family: PingFang SC-中黑体, PingFang SC;
                }
                .count_list{
                    margin: 20px 0 0 0;
                    display: flex;
                    align-content: center;
                    >div{
                        padding: 0 30px 0 0;
                        position: relative;
                        height: 48px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        &:nth-child(-n+2){
                            cursor: pointer;
                        }
                        p{
                            margin: 0;
                            color: #1A1A1A;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 24px;
                            &:last-child{
                                font-weight: normal;
                                font-size: 14px;
                                color: #7F7F7F;
                            }
                        }
                        &:nth-child(n+2){
                            padding: 0 30px;
                            &::after{
                                content: '';
                                width: 1px;
                                height: 20px;
                                background: #D8D8D8;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
        .setting{
            >p{
                text-align: right;
                margin: 25px 0 24px 0;
                font-size: 14px;
                font-family: PingFang SC-常规体, PingFang SC;
                font-weight: normal;
                color: #000000;
                line-height: 14px;
                opacity: 0.4;
            }
            .btn_list{
                display: flex;
                >div:last-child{
                    margin: 0 0 0 10px;
                }
            }
        }
    }
    .auth{
        font-size: 18px;
        font-weight: normal;
        color: #1A1A1A;
        line-height: 18px;
        display: flex;
        align-items: center;
        margin: 0 0 20px 0;
        img{
            width: 22px;
            height: 22px;
            margin: 0 3px 0 0;
        }
    }
    .nick_cont{
        margin: 0;
        padding: 0 0 30px 0;
        font-size: 18px;
        font-weight: normal;
        color: #7F7F7F;
        line-height: 28px;
        height: 28px;
    }
    .cards{
        width: 800px;
        border-top: 1px solid #E8E8E8;
        padding: 30px 0 0 0;
        .btn{
            display: inline-block;
            border-radius: 4px 4px 4px 4px;
            position: absolute;
            left: 30px;
            bottom: 20px;
        }
        .card_title{
            position: absolute;
            left: 30px;
            top: 24px;
            font-size: 20px;
            font-family: PingFang SC-中黑体, PingFang SC;
            font-weight: normal;
            color: #1A1A1A;
            line-height: 24px;
            margin: 0;
        }
        .pro{
            position: relative;
            width: 800px;
            height: 120px;
            border-radius: 8px 8px 8px 8px;
            background: #F8F8F8 url('https://p6.moimg.net/path/dst_project/1063753/202212/1617/2254/22121622547gZpxA0jlr4ydAAZKJzYO5dKVJ3kRD.png') no-repeat center right;
            background-size: contain;
            padding-left: 30px;
            .btn{
                display: inline-block;
                border-radius: 4px 4px 4px 4px;
                margin: 60px 20px 0 0;
                position: static;
            }
        }
        .other{
            padding: 30px 0 0 0;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-row-gap:20px;
            grid-column-gap: 20px;
            >div{
                position: relative;
                width: 385px;
                height: 120px;
                border-radius: 8px 8px 8px 8px;
                &.dongtai{
                    background: #F8F8F8 url('https://p6.moimg.net/path/dst_project/1063753/202212/1617/2254/2212162254lmwGOxRgXAKv7dd2BeErbd12V0p5j7.png') no-repeat center center;
                    background-size: contain;
                }
                &.lockker{
                    background: #F8F8F8 url('https://p6.moimg.net/path/dst_project/1063753/202212/1617/2254/2212162254mWqjX74b1oOE922B9wyQrZxaGNVnD9.png') no-repeat center center;
                    background-size: contain;
                }
                &.support{
                    background: #F8F8F8 url('https://p6.moimg.net/path/dst_project/1063753/202212/1617/2254/2212162254Rlaex12qJdYEj44bNQzpw98PL0ABGZ.png') no-repeat center center;
                    background-size: contain;
                }
                &.yunyang{
                    background: #F8F8F8 url('https://p6.moimg.net/path/dst_project/1063753/202212/1617/2254/22121622542PQa6Xr4N5Yv0bbYMOyjDWA7KmZ3ln.png') no-repeat center center;
                    background-size: contain;
                }
            }
        }
    }
    .nav{
        padding: 10px 0 0 0;
        &.single{
            padding: 40px 0 0 0;
        }
        strong{
            display: block;
            font-size: 18px;
            font-weight: 500;
            color: #1A1A1A;
            line-height: 21px;
        }
        .order_list{
            margin: 20px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            >.item{
                padding: 0 26px;
                width: 132px;
                height: 80px;
                position: relative;
                &:nth-child(-n+4){
                    &::after{
                        content: "";
                        position: absolute;
                        right: -1px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 49px;
                        background: #E8E8E8;
                    }
                }
                .block{
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
                    .icon{
                        font-size: 28px;
                        line-height: 28px;
                    }
                    p{
                        margin: 0;
                        padding: 10px 0 0 0;
                        font-size: 14px;
                        color: #1A1A1A;
                        line-height: 14px;
                    }
                    &:hover{
                        background: #F8F8F8;
                        border-radius: 8px 8px 8px 8px;
                    }
                    .num {
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        padding: 0 6px;
                        height: 20px;
                        border: 2px solid #fff;
                        border-radius: 10px;
                        font-style: normal;
                        font-size: 12px;
                        background-color: #FF4A4A;
                        color: #fff;
                        position: absolute;
                        left: 50%;
                        top: 4px;
                    }
                }
            }
        }
    }
    .pros{
        padding: 40px 0 0 0;
        position: relative;
        >img{
            width: 60px;
            height: 60px;
            position: absolute;
            bottom: 195px;
            z-index: 458;
            cursor: pointer;
            &.back{
                left: 4px;
            }
            &.next{
                right: 4px;
            }
        }
        strong{
            display: block;
            font-size: 18px;
            font-weight: 500;
            color: #1A1A1A;
            line-height: 21px;
        }
        .container{
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
                display: none;
            }
        }
        ::v-deep(.pro_list) {
            display: flex;
            white-space: nowrap;
            align-items: flex-start;
            margin: 20px 0 0 0;
            .pro-item{
                flex-shrink: 1;
                display: inline-block;
                min-width: 240px;
                height: 428px;
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                border: 1px solid #E8E8E8;
                margin: 0 16px 0 0;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
}
</style>
